import { __decorate } from "tslib";
import { mainStore, marketingDigitalStore } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import PopupPagamentoPix from './PopupPagamentoPix.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import PixIntegradorPagamentoDialog from '@/components/PixIntegradorPagamentoDialog.vue';
let Stepper2 = class Stepper2 extends Vue {
    constructor() {
        super(...arguments);
        this.financeiro_parcela = {};
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get agenciaFinanceiro() {
        return marketingDigitalStore.agenciaFinanceiro;
    }
    get mesVigencia() {
        // return format(addMonths(new Date(), 1), 'LLLL', { locale: ptBR });
        return 'Outubro';
    }
    pagarClick() {
        const form = this.$refs.PixIntegradorPagamentoDialog;
        this.agenciaFinanceiro.financeiro_categoria_nome = 'Contratação da Agência';
        form.show(this.agenciaFinanceiro);
    }
    async showPix(item) {
        // @ts-ignore
        const form = this.$refs.PixIntegradorPagamentoDialog;
        if (this.userProfile.company.escola_id == 1) {
            item.syngoo_mensagem_id = 9;
        }
        else if (this.userProfile.company.escola_id == 2) {
            item.syngoo_mensagem_id = 16;
        }
        else if (this.userProfile.company.escola_id == 3) {
            item.syngoo_mensagem_id = 17;
        }
        form.show(item);
    }
};
Stepper2 = __decorate([
    Component({
        components: {
            PopupPagamentoPix,
            ButtonComponent,
            PixIntegradorPagamentoDialog,
        },
    })
], Stepper2);
export default Stepper2;
